<template>
    <div class="max-w-7xl mx-auto shadow-md p-4  rounded-lg">
        <div class="w-full bg-brand p-2 rounded-lg">
            <label for="" class="text-2xl text-white">Role And Permission</label>
        </div>
        <div class="mt-4 bg-white">
            <div class="text-left p-4" 
            v-if="ability.includes('Role Create') || ability.includes('*') ">
                <router-link to="roles/create" class="bg-blue-500 text-white px-6 py-2 rounded-md"> Create </router-link>
            </div>
            <table class="table-auto mx-auto w-full text-sm text-left" 
                v-if="ability.includes('Role List') || ability.includes('*') ">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                        <th scope="col" class="px-6 py-3">SL</th>
                        <th scope="col" class="px-6 py-3">Name</th>
                        <th scope="col" class="px-6 py-3 text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(mentor, index) in mentor_assigns" :key="mentor.id" class="bg-white border-b last:border-none">
                        <td scope="row" class="px-4 py-2 font-medium text-gray-900  whitespace-nowrap">{{ ++index }}</td>
                        <td>{{ mentor.name }}</td>
                        <td class="flex gap-2 items-center justify-center">
                            <router-link :to="{ name:'roleEdit', params:{ id:mentor.id }}" class="bg-blue-500 text-white p-1 rounded-md"
                            title="Create" 
                            v-if="ability.includes('Role Edit') || ability.includes('*') ">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                </svg>
                            </router-link>
                            <div  class="bg-red-500 text-white p-1 rounded-md cursor-pointer" @click="deleteData(mentor.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import Select from "../../../components/select.vue";
import Btn from "../../../components/btn.vue";
import axios, { get_login_token } from "../../../store/axios";
export default {
    components:{
        Select,
        Btn
    },
   
    created(){
        this.$store.dispatch('roles/roleList');
    },
    computed:{
        mentor_assigns(){
            return this.$store.state.roles.roles_list;
        },
         ability(){
            return this.$store.state.auth.abilities || '';
        }
    },
    methods:{
        deleteData(data){
             axios().delete(`/api/roles/${data}`)
             .then( () => {
                this.$toast.success(`Role Successfully Deleted !!!`,{
                   position: "top",
                   duration:5000
                });
                this.$store.dispatch('roles/roleList');
             });
        }
    }
}
</script>